import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import HeroCouple from "../../images/hero-couple.svg"
import Button from "../Button"
import './index.css';

const HeroBackgroundSection = () => (
  <section className="hero">
    <img
      src={HeroCouple}
      alt="A couple of people looking for the most reliable taxi service"
    />
    <div className="wrapper hero_main">
      <h1>
        Safe, reliable, quality transportation services
        <br />
        in South India.
      {/* <p>Bangalore, Puttaparthi and surrounds.</p> */}
      </h1>
      <a href="mailto:arjuna5219@yahoo.com?subject=Get a Quote">
        <Button>Get a quote</Button>
      </a>
    </div>
  </section>
)

const HeroMain = styled(HeroBackgroundSection)`
  img {
    width: 25px;
    min-height: 460px;
  }
  .hero.h1 {
    padding-top: 150px;
  }
`

export default HeroMain
