import React from "react"
import "./index.css"
import Button from "../Button"
import ParthiBlissImage from "../../images/parthi_bliss.svg"

const Pricing = () => (
  <section id="pricing" className="wrapper">
    <h2>Our Packages</h2>
    <div id="pricing-cards">
      <aside className="pricing-card pricing-card__parthi_bliss">
        <h1>Parthi Bliss</h1>
        <img src={ParthiBlissImage} alt="parthi bliss image" />
        <h4>Bangalore to Puttaparthi</h4>
        <h2>INR 4200</h2>
        <p>
          This includes taxi hire for a return trip from Bangalore Airport to
          Puttaparthi. <br />
          <i>A one way trip will cost INR 2100 </i>
        </p>
        <a href="mailto:arjuna5219@yahoo.com?subject=Inquiry">
          <Button>Enquire</Button>
        </a>
      </aside>
      <aside className="pricing-card pricing-card__temple_visit">
        <h1>Temple Visit</h1>
        <img src={ParthiBlissImage} alt="parthi bliss image" />
        <h4>bangalore to south india</h4>
        <h2>INR 4550</h2>
        <p>Visit place like the Golden temple, Arunachal, Puducherry</p>
        <a href="mailto:arjuna5219@yahoo.com?subject=Inquiry">
          <Button>Enquire</Button>
        </a>
      </aside>
    </div>
  </section>
)

export default Pricing
