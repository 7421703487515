import React from "react"
import styled from "styled-components"

const CustomButton = styled.button`
  background-image: linear-gradient(-225deg, #faca2b 15%, #f29a13 85%);
  box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
  border-radius: 30px;
  border: none;
  padding: 14px 37px;
  font-weight: 600;
  font-size: 23px;
  justify-self: center;
  transition: 0.9s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: white;
  min-width: 175px;

  &:hover {
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    transform: translateY(-3px);
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    font-size: 20px;
  }
`

// Define what props.theme will look like
const theme = {
  main: "mediumseagreen",
}

const Button = ({ children }) => <CustomButton>{children}</CustomButton>

export default Button
