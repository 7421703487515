import React from "react"
import { Element } from "react-scroll"

import Layout from "../layouts"
import SEO from "../components/seo"
import HeroMain from "../components/HeroMain"
import UniqueSellingPosition from "../components/UniqueSellingPosition"
import Pricing from "../components/Pricing/Pricing"
import Drivers from "../components/Drivers"
import Testimonials from "../components/Testimonials"
import CallToAction from "../components/CallToAction"
import TestimonialTest from "../components/Testimonials/TestimonialTest"
import AutoPlayCarousel from "../components/Testimonials/AutoPlayCarousel"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { TestimonialSlider } from "../components/Testimonials/TestimonialSlider"

const IndexPage = () => (
  <Layout>
    <SEO title="Sai Ganga Tours & Travels" />
    <div>
      <Element name="home" className="element">
        <HeroMain className="hero_main" />
      </Element>

      <Element name="about" className="element">
        <section className="usp">
          <UniqueSellingPosition />
        </section>
      </Element>

      <Element name="our-packages" className="element">
        <Pricing />
      </Element>

      {/* <Element name="our-drivers" className="element">
        <Drivers />
      </Element> */}

      <Element name="testimonials" className="element">
        <Testimonials />
      </Element>
      <Element name="contact" className="element">
        <CallToAction />
      </Element>
    </div>
  </Layout>
)

export default IndexPage
