import React from "react"
import Slider from "react-slick"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import ReactMarkdown from "react-markdown"

const TestimonailCard = styled.div`
  max-width: 450px;
  height: auto;
  padding: 8px;
  text-align: center;
  background-color: #fefefe;
  box-sizing: border-box;
  /* margin: 8px; */
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-low);
  p {
    font-size: 14px;
  }
`

export const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    // fade: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <>
          <Slider {...settings}>
            {data.allContentfulTestimonial.edges.map((edge) => (
              <TestimonailCard>
                <ReactMarkdown>{edge.node.slug.slug}</ReactMarkdown>
                <small className="testimonial">- {edge.node.fullName}</small>
              </TestimonailCard>
            ))}
          </Slider>
        </>
      )}
    />
  )
}

export const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulTestimonial {
      edges {
        node {
          fullName
          slug {
            slug
          }
        }
      }
    }
  }
`
