import React from "react"
import styled from "styled-components"
import "./index.css"
import Button from "../Button"

const CTA = ({ className }) => (
  <section id="cta">
    <div className="wrapper">
      <h1> Have a few questions? </h1>{" "}
      <p>
        You can reach us directly by calling us or simply enquire for a
        personalised quote.
      </p>{" "}
      <a
        className="cta__secondary"
        href="mailto:arjuna5219@yahoo.com?subject=Get a Quote"
      >
        <Button>Get a quote</Button>
      </a>{" "}
      &nbsp;
      <a href="tel:+91 98 6682 9116">
        <Button>Call us!</Button>
      </a>
    </div>{" "}
  </section>
)

const CallToAction = styled(CTA)``
const Emphasize = styled.span`
  text-decoration: underline;
`


export default CallToAction
