import React from "react"
import styled from "styled-components"
import "./index.css"
import QuoteIcon from "../../images/quote-icon.svg"
import { TestimonialSlider } from "./TestimonialSlider"

const TestimonialsSection = ({ className }) => (
  <section id="testimonials">
    <aside >
      <h1>Our customers already love us</h1>
      <div>
        <img src={QuoteIcon} alt="Quote" />
      </div>
      <TestimonialSlider />
    </aside>{" "}
  </section>
)

const Testimonials = styled(TestimonialsSection)`
`
const Emphasize = styled.span`
  text-decoration: underline;
`

export default Testimonials
