import React from "react"
import styled from "styled-components"
import "./index.css"

const USP = ({ className }) => (
  <section id="usp">
    <div className="wrapper usp-hero">
      <h1>We are not just any taxi service…</h1>
      <p>
        Sai Ganga Tours is a small family owned business taking pride in giving
        customers a joyful and comfortable experience when they choose to travel
        with us. Our drivers are former <Emphasize>students of Swami</Emphasize>
        , who have dedicated their lives to serving the Divine Mother. The
        vehicles you travel in are infused with Shakti from the Divine Mother.
        Our drivers go above and beyond to look after you.
        <p>
          We welcome local travellers as well as travellers from abroad and
          cater to your needs in a friendly, professional manner. Our drivers
          can speak Telugu, Hindi and English and have over 15 years driving
          experience.
        </p>
      </p>
    </div>{" "}
  </section>
)

const UniqueSellingPosition = styled(USP)``
const Emphasize = styled.span`
  text-decoration: underline;
`

export default UniqueSellingPosition
